$popup-height: 30px;

.map-floor-switcher {
  position: absolute;
  width: 50px;
  height: $popup-height;
  border-radius: 20px;
  border: solid 2px rgb(0 0 0 / 54%); // Like the button;
  transform: translateX(-50%) translateY(15px);
  background: rgb(255 255 255 / 70%);

  .rs-popup-close-bt {
    display: none;
  }

  .rs-popup-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $popup-height;

    > div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      border-right: solid 2px lightgray;
    }

    > div:first-child {
      p {
        color: rgb(0 0 0 / 54%); // Like the button
        font-weight: bold;
        padding-top: 1px;
        padding-left: 2px;
      }
    }

    > div:last-child {
      border: none;
    }
  }
}
