@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.rd-routing-menu {
  width: 100%;
  display: flex;
  z-index: 1;
  flex-direction: column;

  > div {
    z-index: 2;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    max-height: 100vh;
  }

  .rd-routing-menu-toggle-close {
    position: sticky;
    bottom: 0;
  }

  .rd-routing-menu-toggle-open {
    background-color: white;
    box-shadow: 0 0 5px #555;
    position: absolute;
  }

  .rd-routing-menu-toggle-close,
  .rd-routing-menu-toggle-open {
    cursor: pointer;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    color: #555;
    width: 100%;
    z-index: 1;
  }

  .rd-routing-menu-toggle-close:hover {
    background-color: rgb(0 0 0 / 5%);
  }

  .rd-mot-checkbox {
    display: flex;
    align-items: center;
    padding: 10px 10px 0 0;
  }

}

@media (width >= 768px) {
  .rd-routing-menu {
    width: 700px;
    flex-direction: row;

    > div {
      margin-top: 0;
      position: relative;
    }

    .rd-routing-menu-toggle-close,
    .rd-routing-menu-toggle-open {
      background-color: #3f51b5;
      height: 48px;
      width: 32px;
      color: white;
      position: absolute;
      box-shadow: none;
    }

    .rd-routing-menu-toggle-close {
      position: absolute;
      box-shadow: none;
      top: 0;
      right: 0;
    }

    .rd-routing-menu-toggle-close:hover,
    .rd-routing-menu-toggle-open:hover {
      background-color: #545db8;
    }
  }
}
