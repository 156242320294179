.rd-info-dialog {
  position: relative;
  height: 300px;
  width: calc(100% - 20px);

  .rd-info-close-button {
    position: absolute;
    right: 0;
    top: 5px;
  }

  .rd-info-dialog-header {
    border-top: 1px #999 solid;
    margin: 20px 0 10px 20px;
    padding-top: 10px;
    font-weight: bold;
  }

  .rd-info-dialog-legend {
    position: absolute;
    font-size: 13px;
    right: 5px;

    svg {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px 0 8px;
      height: 17px;
      width: 17px;
    }
  }

  .recharts-wrapper {
    padding-top: 30px;

    .recharts-surface {
      overflow: visible;

      text {
        fill: rgb(102 102 102);
      }
    }

    .recharts-tooltip-wrapper {
      visibility: visible !important;
    }

    .rd-tootip-wrapper {
      padding: 5px;
      background-color: rgb(0 0 0 / 10%);
      border: 1px solid rgb(255 255 255 / 50%);
    }
  }
}
