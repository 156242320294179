@import url('~ol/ol.css');

.rs-map {
  position: absolute;
  inset: 0 0 24px;
}

.rs-copyright {
  position: absolute;
  bottom: 28px;
  left: 5px;
  margin-right: 75px;
  font-size: 0.8em;
  color: #555;

  a {
    color: #555;
  }
}
