.rd-result-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.rd-result-list::-webkit-scrollbar {
  appearance: none;
  width: 0;
  height: 0;
}
