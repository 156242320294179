body {
  margin: 0;
}

#root {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

a {
  color: #555;
  text-decoration: none;
}
